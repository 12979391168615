<template>
  <div>
    <div
      v-if="isLoadingComplete"
      class="d-flex align-items-center"
    >
      <strong>Loading...</strong>
      <b-spinner class="ml-auto" />
    </div>
    <table-coupon-type
      v-show="!showForm"
      :coupon-types="tableData"
      @addCouponType="addCouponType($event)"
      @editCouponType="editCouponType($event)"
      @deleteCouponType="confirmDeleteCouponType($event)"
    />
    <form-coupon
      v-show="showForm"
      :action="action"
      @closeForm="closeForm"
      @actionSave="actionSave"
    />
  </div>
</template>

<script>

import { BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapMutations, mapState } from 'vuex'

import TableCouponType from '@/components/catalogs/coupon-type/TableCouponType.vue'
import FormCoupon from '@/components/catalogs/coupon-type/FormCouponType.vue'

export default {
  components: {
    BSpinner,
    TableCouponType,
    FormCoupon,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      showForm: false,
      action: '',
    }
  },

  computed: {
    ...mapState('couponType', ['couponTypes', 'isLoading']),
    tableData() {
      return this.couponTypes
    },
    isLoadingComplete() {
      return this.isLoading
    },
  },

  mounted() {
    this.searchCouponType()
  },

  methods: {
    ...mapActions('couponType',
      ['searchCouponType', 'createCouponType', 'deleteCouponType', 'updateCouponType', 'restoreCouponType']),
    ...mapMutations('couponType',
      { setCouponType: 'setCouponType' }),
    addCouponType() {
      this.showForm = true
      this.restoreCouponType()
      this.action = 'Create'
    },
    editCouponType(couponType) {
      this.setCouponType(couponType)
      this.showForm = true
      this.action = 'Edit'
    },
    closeForm() {
      this.showForm = false
    },
    actionSave(couponType) {
      if (this.action === 'Edit') {
        this.updateCouponType(couponType)
      }
      if (this.action === 'Create') {
        this.createCouponType(couponType)
      }
      this.showForm = false
    },
    async confirmDeleteCouponType(couponType) {
      const response = await this.$swal({
        title: `Are you sure to delete the catalog type ${couponType.name}.?`,
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-Are you sure',
        },
      })
      if (response && response.value) {
        this.deleteCouponType(couponType)
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
