<template>
  <b-card
    no-body
  >
    <div class="card-header">
      <b-card-title>
        {{ title }}
      </b-card-title>
      <feather-icon
        class="float-right cursor-pointer"
        size="21"
        icon="XIcon"
        @click="$emit('closeForm')"
      />
    </div>

    <template>
      <b-card-body>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-row>
                  <!--  Name -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Name</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Name"
                      >
                        <b-form-input
                          v-model="couponTypeCopy.name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Description -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Description</label>
                      <validation-provider
                        #default="{ errors }"
                        rules=""
                        name="Description"
                      >
                        <b-form-input
                          v-model="couponTypeCopy.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Description"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- submit button -->
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      type="submit"
                      class="mr-1"
                      @click.prevent="validationForm"
                    >
                      Save
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </template>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardBody, BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    BCardBody,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  props: {
    action: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      title: 'Update Coupon',
      required,
    }
  },
  computed: {
    ...mapState('couponType', {
      couponType: 'couponType',
    }),
    couponTypeCopy() {
      return { ...this.couponType }
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('actionSave', this.couponTypeCopy)
        }
      })
    },
  },
}
</script>
